// ::Imports pre-render. Must be after reset
// ------------------------------------------------------------
//@import "libs/required";
@import "libs/reset";
@import "libs/settings";
@import "libs/mixins";
@import "libs/grid-mixins";
@import "libs/placeholders";

// Use SASS globbing to import all _.scss files in the module folder.
// These should be independent modules that follow the BEM/SMACSS way

@import "modules/_01-atoms.scss";
@import "modules/_02-molecules.scss";
@import "modules/_04-templates.scss";
@import "modules/_05-royalslider.scss";
@import "modules/_06-rs-default.scss";


/* ::Base styles */
/* ------------------------------------------------------------ */

html {
  font-size: 100%;
  @include respond-to-max(768px, $IE9: false) { // Stop at precise width, as we're talking about devices
    -webkit-text-size-adjust: 100%; // Stops text scaling in iOS.
  }
}

body {
  @extend .font-body;
  background: $body-color;
  color: $base-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* ::Import styles */
/* ------------------------------------------------------------ */

@import "libs/typography";            // Base level typography
@import "libs/forms";                 // Forms
@import "add-ons/module-typeset";     // Specific typesetting
@import "libs/grid";                  // Grid system

// Bootstrap forms
@import "libs/bootstrap/variables";                 
@import "libs/bootstrap/mixins/buttons";     
@import "libs/bootstrap/mixins/size";     
@import "libs/bootstrap/mixins/tab-focus";     
@import "libs/bootstrap/mixins/forms";    
@import "libs/bootstrap/mixins/table-row";    
@import "libs/bootstrap/mixins/vendor-prefixes";    
@import "libs/bootstrap/button-groups"; 
@import "libs/bootstrap/buttons"; 
@import "libs/bootstrap/forms"; 
@import "libs/bootstrap/input-groups"; 
@import "libs/bootstrap/tables";


/* ::Print */
/* ------------------------------------------------------------ */

@media print {
  body {
    font-size: percentage(10 / 16);
  }
}
