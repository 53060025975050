// ------------------------------------------------------------
// Global typography
// ------------------------------------------------------------

@font-face {
    font-family: 'Libre Caslon Text Italic';
    src: url('../fonts/librecaslontext-italic-webfont.eot');
    src: url('../fonts/librecaslontext-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/librecaslontext-italic-webfont.woff2') format('woff2'),
         url('../fonts/librecaslontext-italic-webfont.woff') format('woff'),
         url('../fonts/librecaslontext-italic-webfont.ttf') format('truetype'),
         url('../fonts/librecaslontext-italic-webfont.svg#libre_caslon_textitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'Libre Caslon Text';
    src: url('../fonts/librecaslontext-regular-webfont.eot');
    src: url('../fonts/librecaslontext-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/librecaslontext-regular-webfont.woff2') format('woff2'),
         url('../fonts/librecaslontext-regular-webfont.woff') format('woff'),
         url('../fonts/librecaslontext-regular-webfont.ttf') format('truetype'),
         url('../fonts/librecaslontext-regular-webfont.svg#libre_caslon_textregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// ------------------------------------------------------------
// Fonts
// ------------------------------------------------------------

.font-body{
  font-family: 'Libre Caslon Text', serif;
}

.font-body-italic{
  font-family: 'Libre Caslon Text Italic', serif;
}

// ------------------------------------------------------------
// Core typography
// ------------------------------------------------------------

h1,
h2,
h3,
h4,
h5
h6{
  @include font-size($font-sizes);
  margin-bottom: .5em;
  p + &{
    margin-top: .5em;
  }
}

p{
  @include font-size($font-sizes);
  margin-bottom: .5em;
  text-indent: 30px;
}

a{
  @include font-size($font-sizes);
  color: $action;
  text-decoration: underline;
  @include link-active-styles {   // Mixin for interactions (hover,focus,active)
    color: $action;
    text-decoration: none;
  }
}

time{
  @include font-size($font-sizes);
  @extend .font-body-italic;
}

figcaption{
  @include font-size($font-sizes);
  @extend .font-body;
  margin-top: 4px;
}

.active{
  @extend .font-body-italic;
}

em,
i{
  font-style: italic;
}

b,
strong{
  font-weight: bold;
}

p sub, p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small{
  @include font-size($font-sizes);
}

p abbr {
  border-bottom: 1px dotted $base-color;
  cursor: help;
}

p q,
p cite{
 &:before{
    content:'"';
 }
 &:after{
    content:'"';
 }
}


p mark{
  background-color: $action;
  color: $body-color;
}

p code,
p samp{
  font-family: monospace;
  @include font-size($font-sizes);
}

pre{
  font-family: monospace;
  @include font-size($font-sizes);
}

hr{
  background: $base-color;
  height: 4px;
  border: none;
  margin: 0;
}

dl{
  dt{
    @include font-size($font-sizes);
    font-weight: bold;
    margin-bottom: $spacing-xxs;
    &:first-of-type{
      padding-top: $spacing-sm;
      border-top: $default-border-width $base-color solid;
    }
  }
  dd{
    padding-bottom: $spacing-sm;
    margin-bottom: $spacing-sm;
    border-bottom: $default-border-width $base-color solid;
  }
}

ol, ul, pre, input, textarea, dl                        {@include font-size($font-sizes);}
ul, ol                                                  {@include nobullet;}
ul ul                                                   {@include font-size($font-sizes);}
ol ol                                                   {@include font-size($font-sizes);}             
.text ul,                                               {@include unordered; margin-left: 1em;}
.text ol                                                {@include ordered; margin-left: 1em;}
.text > ul,                                             {margin-bottom: 1em;}
.text > ol                                              {margin-bottom: 1em;}          
li                                                      {line-height: inherit;}
