// ------------------------------------------------------------
// Patternlab molecules
// ------------------------------------------------------------

/* ::navigation */
/* ------------------------------------------------------------ */

@media (min-width: $screen-sm-min){
   .aside{
      display: flex;
      flex-direction: column;
      height: calc(100vh - 80px * 2);
      .menu{
         margin-top: auto;
      }
   }
}

header{
 @include margin-bottom($spacing-sm-responsive);
}
@media (max-width: $screen-xs-max){
   header h1{
      text-align: center;
   }
   nav.menu{
      margin-bottom: 40px;
   }
}


/* ::image link hover */
/* ------------------------------------------------------------ */
@media (min-width: $screen-sm-min){
   .relative{position: relative;}
   .project-list{
      position: static !important;
      figure{
         position: relative;;
         img{
            position: absolute;
            top: 0;
            right: 0;
         }
      }
      li:not(:hover),
      article:not(:hover){
         figure{
            display: none;
         }
      }
   }
   .article-list,
   .project-list{
      figure{
         position: relative;
         img{
            position: absolute;
            top: 0;
            left: 0;
         }
      }
      article:not(:hover) figure{
         opacity: 0;
      }
   }
}

/* ::gallery hover */
/* ------------------------------------------------------------ */
@media (min-width: $screen-sm-min){
   .royalSlider:hover{
      cursor: pointer;
      cursor: url('../images/arrow.svg'), auto;
   }
}

/* Project */
/* ------------------------------------------------------------ */
.project-list{
   @media (min-width: $screen-sm-min){
      height: calc(100vh - 80px * 2);
      a{
         display: block;
         padding-bottom: .5em;
         h2{
            margin-bottom: 0;
         }
      }
   }
   @media (max-width: $screen-xs-max){
      h2{
         display: block;
         margin-bottom: .5em;
      }
      img{
         padding-left: 30px;
      }
      li{
         margin-bottom: .5em;
      }
   }
}

@media (max-width: $screen-xs-max){
   .royalSlider img{
      margin-bottom: .5em;
      margin-top: .5em;
      &:first-of-type{
         padding-left: 30px;
      }
   }
}

.project-detail{
   @media (max-width: $screen-xs-max){
      h1{
         display: block;
         margin-bottom: 0;
      }
      img{
         margin-bottom: .5em;
         margin-top: .5em;
      }
       img:first-of-type{
         padding-left: 30px;
      }
   }
}

/* Infos */
/* ------------------------------------------------------------ */
.contact{
   margin-bottom: 2em;
   a{
      display: block;
   }
}

/* Actualités */
/* ------------------------------------------------------------ */
.article-list{
   @media (min-width: $screen-sm-min){
      height: calc(100vh - 80px * 2);
   }
   article{
      margin-bottom: 3em;
      h1{
         margin-bottom: 0;
      }
      time{
         margin-bottom: .5em;
         display: block;
      }
   }
}
