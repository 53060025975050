// ------------------------------------------------------------
// Forms
// ------------------------------------------------------------


label, legend, select {
	display: block;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

textarea {
	overflow: auto;
	vertical-align: top;
}


input[type="radio"],
input[type="checkbox"] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	*margin-top: 0;
	line-height: normal;
	cursor: pointer;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
	width: auto;
}

select,
input[type="file"] {
	*margin-top: 4px; /* In IE7, the height of the select element cannot be changed by height, only font-size */
}

select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: thin dotted #333;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}