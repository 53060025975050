// ------------------------------------------------------------
// Patternlab templates
// ------------------------------------------------------------

/* ::general */
/* ------------------------------------------------------------ */
body{
  max-width: 1600px;
  margin: auto;
  padding: 40px 0;
  @media (min-width: $screen-sm-min){
    padding: 80px (80px - ($grid-gutter-width/2));
    min-height: 100vh;
    &:before,
    &:after{
      content: "";
      height: 80px;
      width: 100%;
      display: block;
      position: fixed;
      left: 0;
      background: $body-color;
      z-index: 2;
    }
    &:before{
      top: 0;
    }
    &:after{
      bottom: 0;
    }
  }
}

.contact p{
  text-indent: 0;
}
