// ------------------------------------------------------------
// Patternlab atoms
// ------------------------------------------------------------

.hide-sm {
    @media all and (max-width: $screen-sm) {
        display: none;
    }
}

.hide-md {
    @media all and (min-width: $screen-sm) and (max-width: $screen-lg) {
        display: none;
    }
}

.hide-lg {
    @media all and (min-width: $screen-lg) {
        display: none;
    }
}

/* ::Global */
/* ------------------------------------------------------------ */

header,
.menu,
.project-list{
  a{
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}

/* ::Images */
/* ------------------------------------------------------------ */

img, video, object {
  max-width: 100%;
  height: auto;
}

img{
  vertical-align: middle;
}